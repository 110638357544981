import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, TextField, Button,
  Snackbar, Alert, Tab, Tooltip, IconButton, Select, MenuItem, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, InputLabel
} from '@mui/material';
import { theme } from '../Theme';
import { recommendDomainsBulk,recommendRenewalDomainsBulk, zeroAndArchiveRecoRenewalDomainsBulk, assignCode, getRenewalDomain, getSocialChildren, getTopLeadEmailAgent, moveToReasearch, allocateRenewalToSocialUser, allocateRenewalToEmailProcessor, moveRenewalToReasearch,getRenewalRecoHubDomain } from '../api';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import QaAqLeadsDialog from '../Common/QaAqLeadsDialog';
import EditIcon from '@mui/icons-material/Edit';
import CustomToggleButton from '../Common/CustomToggleButton';
import RenewalTable from './RenewalTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';

function RecoHub() {
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);

  const [tabValue, setTabValue] = useState('NEW');
  const [loading, setLoading] = useState(false);
  const [isNlDomain, setIsNlDomain] = useState(false);

  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedAction, setSelectedAction] = useState('');
  const [changes, setChanges] = useState({
    reco: {},
    remarks: {},
    recoRemark: {}
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const listTypes = ['NEW', 'ACTIVE', 'EXPIRING', 'EXPIRED'];

  // Add new state for subTab
  const [subTab, setSubTab] = useState('All');

  // Add new state variables
  const [openAllocateDialog, setOpenAllocateDialog] = useState(false);
  const [openEmailAllocateDialog, setOpenEmailAllocateDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [allocating, setAllocating] = useState(false);
  const [children, setChildren] = useState([]); // Social users
  const [emailAgent, setEmailAgent] = useState([]); // Email agents

  useEffect(() => {
    if (tabValue) {
      fetchRecoHubData(tabValue);
    }
  }, [tabValue, subTab, isNlDomain, pagination, sorting, columnFilters, globalSearch]);

  useEffect(() => {
    getSocialChildren().then(res => {
      setChildren(res.data);
    }).catch(err => console.log(err));

    getTopLeadEmailAgent().then(res => {
      setEmailAgent(res.data);
    }).catch(err => console.log(err));
  }, []);

  const fetchRecoHubData = useCallback((
    tab,
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);
    let apiCall = getRenewalRecoHubDomain;

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    try {
      apiCall({
        isNlDomain,
        domainState: tab.toLowerCase(),
        processingState: subTab.toLowerCase(),
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
      })
        .then(response => {
          if (response && response.data) {
            const transformedData = response.data.content.map(item => ({
              acquShortlistedDomain: {
                id: item.id,
                domain: item.domain,
                list: item.list,
                host: item.host,
                tld: item.tld,
                wby: item.wby,
                aby: item.aby,
                eby: item.eby,
                reg: item.reg,
                regx: item.regx,
                sg: item.sg,
                cpc: item.cpc,
                bids: item.bids,
                price: item.currentPrice,
                est: item.EST,
                gdv: item.GDV,
                reco: item.reco,
                apr: item.apr,
                edate: item.edate,
                createdAt: item.dateOfUpload,
                adate: item.dateOfUpload,
                tld_count: item.tld_count,
                tld_count_dev: item.tld_count_dev,
                p: item.PP,
                len: item.length,
                esw: item.ESW,
                gdw: item.GDW,
                aprUnaprBy: item.aprUnaprBy,
                recoBy: item.recoBy,
                spr500: item.spr500,
                sprGdv: item.sprGDV,
                sprGdw: item.sprGDW,
                remarks: item.remarks,
                recoRemark: item.recoRemark,
                aprRemark: item.aprRemark,
                category: item.category,
                portfolio: item.portfolio,
                ltd: item.ltd || false,
                brandable: item.brandable || false,
                generic: item.generic || false,
                autoRenew: item.autoRenew || false,
                status: item.status,
                isApr: item.isApr || false,
                purchaseDate: item.purchaseDate,
                portfolioApiSyncDate: item.portfolioApiSyncDate,
                registrar: item.registrar?.displayName || null,
                presentAllocation: item.presentAllocation
              },
              socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
                const leadData = lead.correctedLead || lead;
                return {
                  id: leadData.id,
                  domain: leadData.domain,
                  domainSnapShotUrl: leadData.domainSnapShotUrl,
                  copyright: leadData.copyright,
                  description: leadData.description,
                  isIrrelevant: leadData.irrelevant ?? false,
                  missedStatus: leadData.missedStatus ?? 0,
                  code: leadData.code,
                  noSocialsAvailable: leadData.noSocialsAvailable ?? false,
                  isInfluencer: leadData.isInfluencer ?? false,
                  leadStatus: leadData.leadStatus ?? "active",
                  wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
                  presentAllocation: leadData.presentAllocation || null,
                  socials: lead.socials?.map(social => {
                    const socialData = social.correctedSocial || social;
                    return {
                      id: socialData.id,
                      platform: socialData.platform.toLowerCase(),
                      status: socialData.status,
                      followers: socialData.followers,
                      employees: socialData.employees,
                      url: socialData.url,
                      userFullName: socialData.userFullName,
                      isIrrelevant: socialData.irrelevant ?? false,
                      missedStatus: socialData.missedStatus ?? 0,
                      wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                    };
                  }) || [],
                  email: lead.emailWorks?.[0]?.emails?.[0] ? {
                    id: lead.emailWorks[0].emails[0].id,
                    email: lead.emailWorks[0].emails[0].email,
                    name: lead.emailWorks[0].emails[0].name,
                    designation: lead.emailWorks[0].emails[0].designation,
                    linkedinUrl: lead.emailWorks[0].emails[0].linkedinUrl
                  } : null
                };
              }) || [],
              socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
              reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark,
              noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable,
              agents: {
                processor: item.socialDomain?.socialWorks?.[0]?.user ? {
                  name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
                  email: item.socialDomain.socialWorks[0].user.email,
                  role: 'Processor'
                } : null,
                reviewer: item.socialDomain?.socialWorks?.[0]?.reviewer ? {
                  name: `${item.socialDomain.socialWorks[0].reviewer.firstName} ${item.socialDomain.socialWorks[0].reviewer.lastName}`,
                  email: item.socialDomain.socialWorks[0].reviewer.email,
                  role: 'Reviewer',
                  reviewStatus: item.socialDomain.socialWorks[0].reviewStatus,
                  reviewRemark: item.socialDomain.socialWorks[0].reviewRemark,
                  noLeadsAvailable: item.socialDomain.socialWorks[0].noLeadsAvailable,
                } : null,
                recommender: item.recoBy ? {
                  name: typeof item.recoBy === 'string'
                    ? item.recoBy
                    : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
                  email: item.recoBy.email,
                  role: 'Recommender'
                } : null,
                approver: item.aprUnaprBy ? {
                  name: typeof item.aprUnaprBy === 'string'
                    ? item.aprUnaprBy
                    : `${item.aprUnaprBy.firstName} ${item.aprUnaprBy.lastName}`,
                  email: item.aprUnaprBy.email,
                  role: 'Approver'
                } : null
              }
            }));
            setData(transformedData);
            setTotalElements(response.data.totalElements || 0);
          } else {
            console.error('Invalid API response format:', response);
            setData([]);
            setTotalElements(0);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalElements(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchRecoHubData:', error);
      setLoading(false);
      setData([]);
      setTotalElements(0);
    }
  }, [isNlDomain, columnFilters, sorting, pagination, globalSearch, subTab]);

  const handleRecoChange = useCallback((id, value) => {
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      setChanges(prev => ({
        ...prev,
        reco: {
          ...prev.reco,
          [id]: numValue
        }
      }));
    }
  }, []);

  const handleRecoRemarkChange = useCallback((id, value) => {
    setChanges(prev => ({
      ...prev,
      recoRemark: {
        ...prev.recoRemark,
        [id]: value
      }
    }));
  }, []);

  const handleCodeChange = useCallback((rowId, value, leadCount) => {
    setData(prevData => prevData.map(item =>
      item.acquShortlistedDomain.id === rowId
        ? { ...item, codeValue: value, isCodeValid: value.length === leadCount || value.length === 0 }
        : item
    ));
  }, []);

  const handleRemarksChange = useCallback((id, value) => {
    setChanges(prev => ({
      ...prev,
      remarks: {
        ...prev.remarks,
        [id]: value
      }
    }));
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setChanges({ reco: {}, remarks: {}, recoRemark: {} });
    setRowSelection({});
  };

  const handleActionChange = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
    handleActionSubmit(action);
  };

  const handleResearchActionChange = (e) => {
    const action = e.target.value;
    setSelectedAction(action);

    if (action === 'allocate') {
      setOpenAllocateDialog(true);
    } else if (action === 'claim') {
      handleMoveToResearch();
    }
  };

  const handleMoveToResearch = async () => {
    try {
      setLoading(true);
      const selectedRows = Object.keys(rowSelection).map(key => data[key]);
      const selectedDomainIds = selectedRows.map(row => row.acquShortlistedDomain.id);

      if (selectedDomainIds.length === 0) {
        setSnackbar({
          open: true,
          message: 'No valid domains selected',
          severity: 'warning'
        });
        return;
      }

      await moveRenewalToReasearch(selectedDomainIds);
      setSnackbar({
        open: true,
        message: 'Successfully moved domains to research hub',
        severity: 'success'
      });
      setRowSelection({});
      fetchRecoHubData(tabValue);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to move domains to research hub',
        severity: 'error'
      });
    } finally {
      setLoading(false);
      setSelectedAction('');
    }
  };

  const handleAllocateToUser = async () => {
    try {
      setAllocating(true);
      const selectedRows = Object.keys(rowSelection).map(key => data[key]);
      const selectedIds = selectedRows.map(row => row.acquShortlistedDomain.id);

      if (selectedIds.length === 0) {
        setSnackbar({
          open: true,
          message: 'No valid domains selected',
          severity: 'warning'
        });
        return;
      }

      await allocateRenewalToSocialUser({
        domainIds: selectedIds,
        user: selectedUser,
      });

      setSnackbar({
        open: true,
        message: 'Successfully allocated domains to social user',
        severity: 'success'
      });
      setOpenAllocateDialog(false);
      setSelectedAction('');
      setSelectedUser('');
      fetchRecoHubData(tabValue);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to allocate domains to social user',
        severity: 'error'
      });
    } finally {
      setAllocating(false);
    }
  };

  const handleAllocateToEmailUser = async () => {
    try {
      setAllocating(true);
      const selectedRows = Object.keys(rowSelection).map(key => data[key]);
      const selectedIds = selectedRows.map(row => row.acquShortlistedDomain.id);

      if (selectedIds.length === 0) {
        setSnackbar({
          open: true,
          message: 'No valid domains selected',
          severity: 'warning'
        });
        return;
      }

      await allocateRenewalToEmailProcessor({
        domainIds: selectedIds,
        user: selectedUser,
      });

      setSnackbar({
        open: true,
        message: 'Successfully allocated domains to email user',
        severity: 'success'
      });
      setOpenEmailAllocateDialog(false);
      setSelectedUser('');
      fetchRecoHubData(tabValue);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to allocate domains to email user',
        severity: 'error'
      });
    } finally {
      setAllocating(false);
    }
  };

  const handleActionSubmit = async (action) => {
    try {
      setLoading(true);
      const selectedRows = Object.keys(rowSelection).map(key => data[key]);
      const selectedDomains = selectedRows.map(row => ({
        ...row.acquShortlistedDomain,
        reco: changes.reco[row.acquShortlistedDomain.id] ?? row.acquShortlistedDomain.reco,
        remarks: changes.remarks[row.acquShortlistedDomain.id] ?? row.acquShortlistedDomain.remarks,
        recoRemark: changes.recoRemark[row.acquShortlistedDomain.id] ?? row.acquShortlistedDomain.recoRemark
      }));

      // First save codes if any rows have codes
      const rowsWithCodes = selectedRows.filter(row => row.codeValue);
      if (rowsWithCodes.length > 0) {
        const codePayload = rowsWithCodes.map(row => ({
          id: row.socialWorkId,
          topLeads: row.socialLeads.map((lead, index) => ({
            id: lead.id,
            code: row.codeValue[index] || '',
          })),
        }));
        await assignCode(codePayload);
      }

      // Then perform the main action
      let apiCall;
      switch (action) {
        case 'Recommend': apiCall = recommendRenewalDomainsBulk; break;
        case 'ZeroArchive': apiCall = zeroAndArchiveRecoRenewalDomainsBulk; break;
        default: return;
      }

      await apiCall(selectedDomains);

      setRowSelection({});
      setChanges({ reco: {}, remarks: {}, recoRemark: {} });
      setSelectedAction('');
      setSnackbar({
        open: true,
        message: `Domains ${action.toLowerCase()}ed successfully`,
        severity: 'success'
      });

      fetchRecoHubData(tabValue);
    } catch (error) {
      console.error('Error processing domains:', error);
      setSnackbar({
        open: true,
        message: `Failed to ${action.toLowerCase()} domains: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event, rowId, columnKey) => {
    const focusableColumns = ['reco', 'code', 'remarks', 'recoRemark']; // Specify the columns to focus on
    if (event.key === 'Enter') {
      event.preventDefault();
      const currentRowIndex = data.findIndex(item => item.acquShortlistedDomain.id === rowId);
      const nextRow = data[currentRowIndex + 1];
      if (nextRow) {
        const nextInput = document.querySelector(`input[data-row-id="${nextRow.acquShortlistedDomain.id}"][data-column-key="${columnKey}"]`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
      const currentRowIndex = data.findIndex(item => item.acquShortlistedDomain.id === rowId);
      const currentColumnIndex = focusableColumns.indexOf(columnKey);
      const nextColumnKey = focusableColumns[(currentColumnIndex + 1) % focusableColumns.length];
      const nextInput = document.querySelector(`input[data-row-id="${rowId}"][data-column-key="${nextColumnKey}"]`);
      if (nextInput) {
        nextInput.focus();
      } else {
        // If no next input in the same row, move to the first column of the next row
        const nextRow = data[currentRowIndex + 1];
        if (nextRow) {
          const firstInputInNextRow = document.querySelector(`input[data-row-id="${nextRow.acquShortlistedDomain.id}"][data-column-key="${focusableColumns[0]}"]`);
          if (firstInputInNextRow) {
            firstInputInNextRow.focus();
          }
        }
      }
    }
  };

  // Memoize columns to prevent unnecessary re-renders
  const columns = useMemo(() => [
    {
      accessorKey: 'reco',
      header: 'reco',
      size: 50,
      minSize: 50,
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Plum color for 'reco' column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          paddingLeft: '4px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      Cell: ({ row }) => {
        const [localValue, setLocalValue] = useState(
          changes.reco[row.original.acquShortlistedDomain.id] ??
          row.original.acquShortlistedDomain.reco ??
          ''
        );

        const handleChange = (e) => {
          setLocalValue(e.target.value);
        };

        const handleBlur = () => {
          const numValue = Number(localValue);
          if (!isNaN(numValue)) {
            handleRecoChange(row.original.acquShortlistedDomain.id, numValue);
          }
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              type="number"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, row.original.acquShortlistedDomain.id, 'reco')}
              inputProps={{
                'data-row-id': row.original.acquShortlistedDomain.id,
                'data-column-key': 'reco',
                min: 0,
                style: {
                  padding: '1px 4px',
                  width: '30px',
                  fontStyle: 'italic'
                },
                sx: { fontSize: 10, height: 18 }
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" }
              }}
            />
          </Stack>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.original.acquShortlistedDomain.reco;
        const b = rowB.original.acquShortlistedDomain.reco;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    {
      accessorKey: 'apr',
      header: 'apr',

      size: 30,
      minSize: 30,
      maxSize: 40,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Plum color for 'reco' column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '0px',
          margin: '0px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.apr || '-',
      sortingFn: 'basic'
    },
    {
      accessorKey: 'recoRemark',
      header: 'rRemark',
      size: 80,
      minSize: 80,
      maxSize: 90,
      Cell: ({ row }) => {
        const [localValue, setLocalValue] = useState(
          changes.recoRemark?.[row.original.acquShortlistedDomain.id] ??
          row.original.acquShortlistedDomain.recoRemark ??
          ''
        );

        const handleChange = (e) => {
          setLocalValue(e.target.value);
        };

        const handleBlur = () => {
          handleRecoRemarkChange(row.original.acquShortlistedDomain.id, localValue);
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, row.original.acquShortlistedDomain.id, 'recoRemark')}
              inputProps={{
                'data-row-id': row.original.acquShortlistedDomain.id,
                'data-column-key': 'recoRemark',
                style: {
                  padding: '1px 4px',
                  width: '60px',
                  fontStyle: 'italic'
                },
                sx: { fontSize: 10, height: 18 }
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0
              }}
            />
          </Stack>
        );
      }
    },
    {
      accessorKey: 'aprRemark',
      header: 'aRemark',
      size: 80,
      minSize: 80,
      maxSize: 90,
      Cell: ({ row }) => (
        <div className="px-2 py-1">
          {row.original.acquShortlistedDomain.aprRemark || ''}
        </div>
      )
    },
    {
      accessorKey: 'actions',
      header: 'review',
      size: 60,
      Cell: ({ row }) => {
        const hasLeads = row.original.socialLeads?.length > 0;
        const noLeadsAvailable = row.original.socialWorkId && row.original.noLeadsAvailable;
        const canReview = hasLeads || noLeadsAvailable;

        return (
          <Tooltip title={
            noLeadsAvailable ? "Review No Leads Available" :
              hasLeads ? "Edit Leads & Socials" :
                "Socials not done"
          }>
            <span>
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedRow(row.original);
                  setEditDialogOpen(true);
                }}
                disabled={!canReview}
                sx={{
                  '&.Mui-disabled': {
                    opacity: 0.5,
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'code',
      header: 'code',
      size: 50,
      minSize: 50,
      maxSize: 60,
      Cell: ({ row }) => {
        const [localValue, setLocalValue] = useState(
          row.original.socialLeads?.map(lead => lead.code || '').join('') || ''
        );

        useEffect(() => {
          const codes = row.original.socialLeads?.map(lead => lead.code || '').join('') || '';
          setLocalValue(codes);
        }, [row.original.socialLeads]);

        const handleChange = (e) => {
          const newValue = e.target.value.toUpperCase();
          const maxLength = row.original.socialLeads?.length || 0;
          if (newValue.length <= maxLength) {
            setLocalValue(newValue);
          }
        };

        const handleBlur = () => {
          const maxLength = row.original.socialLeads?.length || 0;
          handleCodeChange(
            row.original.acquShortlistedDomain.id,
            localValue,
            maxLength
          );
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, row.original.acquShortlistedDomain.id, 'code')}
              inputProps={{
                'data-row-id': row.original.acquShortlistedDomain.id,
                'data-column-key': 'code',
                style: {
                  padding: '1px 4px',
                  width: '30px',
                  fontStyle: 'italic'
                },
                sx: { fontSize: 10, height: 18 },
                maxLength: row.original.socialLeads?.length || 0
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=text]": { MozAppearance: "textfield" }
              }}
            />
          </Stack>
        );
      }
    },
    {
      accessorKey: 'emailState',
      header: 'estatus',
      size: 60,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialLeads || [];
        const totalLeads = socialLeads.length;

        if (totalLeads === 0) return null;

        let leadsSubmitted = 0;
        let leadsInProgress = 0;
        let leadsNotStarted = 0;
        let unallocated = 0;

        socialLeads.forEach(lead => {
          if (!lead.presentAllocation) {
            unallocated++;
          } else {
            switch (lead.presentAllocation.status) {
              case 'submitted':
                leadsSubmitted++;
                break;
              case 'in progress':
                leadsInProgress++;
                break;
              case 'not started':
                leadsNotStarted++;
                break;
              default:
                leadsNotStarted++;
            }
          }
        });

        // Determine the state
        let state;
        if (unallocated === totalLeads) {
          state = 'unallocated';
        } else if (leadsSubmitted === totalLeads) {
          state = 'done';
        } else if (leadsSubmitted > 0 || leadsInProgress > 0) {
          state = 'processing';
        } else if (leadsNotStarted === totalLeads) {
          state = 'not_started';
        } else {
          state = 'pending';
        }

        const getTooltipText = () => {
          const parts = [];

          // Add user info for the first lead if available
          const firstLead = socialLeads[0];
          if (firstLead?.presentAllocation?.user) {
            const user = firstLead.presentAllocation.user;
            parts.push(`Processor: ${user.firstName} ${user.lastName}`);
          }

          // Add status counts
          if (unallocated > 0) parts.push(`${unallocated} unallocated`);
          if (leadsSubmitted > 0) parts.push(`${leadsSubmitted} submitted`);
          if (leadsInProgress > 0) parts.push(`${leadsInProgress} in progress`);
          if (leadsNotStarted > 0) parts.push(`${leadsNotStarted} not started`);

          return parts.join('\n');
        };

        return (
          <Tooltip
            title={getTooltipText()}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: 'pre-line',
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  border: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  '& .MuiTooltip-arrow': {
                    color: 'divider',
                  },
                },
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {state === 'done' && (
                <CheckCircleIcon sx={{ color: 'success.main', fontSize: '1.1rem' }} />
              )}
              {state === 'processing' && (
                <EmailIcon sx={{ color: 'warning.main', fontSize: '1.1rem' }} />
              )}
              {state === 'not_started' && (
                <EmailIcon sx={{ color: 'info.main', fontSize: '1.1rem' }} />
              )}
              {state === 'pending' && (
                <EmailIcon sx={{ color: 'text.disabled', fontSize: '1.1rem' }} />
              )}
              {state === 'unallocated' && (
                <EmailIcon sx={{ color: 'text.disabled', fontSize: '1.1rem', opacity: 0.5 }} />
              )}
            </Box>
          </Tooltip>
        );
      }
      }
  ], [changes, handleRecoChange, handleRemarksChange, handleRecoRemarkChange, handleCodeChange, data]);

  const handleDataChange = useCallback(() => {
    fetchRecoHubData(tabValue);
  }, [tabValue]);

  const toolbarStyles = {
    select: {
      height: '34px',
      backgroundColor: '#8797A8',
      color: '#FFFFFF',
      '.MuiSelect-select': {
        padding: '4px 8px',
        fontSize: '0.85rem',
        color: '#FFFFFF',
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
      },
      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
      '&:hover': {
        backgroundColor: '#8797A8',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        opacity: 0.9,
      },
    },
    button: {
      minHeight: '34px',
      backgroundColor: '#8797A8',
      color: '#FFFFFF',
      padding: '4px 8px',
      fontSize: '0.85rem',
      textTransform: 'none',
      '&.MuiButton-outlined': {
        border: '1px solid #FFFFFF',
        '&:hover': {
          border: '1px solid #FFFFFF',
        },
      },
      '&:hover': {
        backgroundColor: '#8797A8',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        opacity: 0.9,
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
        color: '#FFFFFF',
      },
    }
  };

  const columnVisibility = {
    createdAt: false,
    spr500: false,
    len: false,
    host: false,
    tld: false,
    aby: false,
    remarks: false,
    agents: false,
    actions: false,
    recoRemark: false,
    aprRemark: false,
  }

  // Add handler for subTab changes
  const handleSubTabChange = (event, newValue) => {
    if (newValue !== null) {
      setSubTab(newValue);
    }
  };

  // Modify the toolbar render function to include the ToggleButtonGroup
  const renderTopToolbarCustomActions = ({ table }) => (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <Stack direction="row" spacing={1}>
        <Select
          size="small"
          value={selectedAction}
          onChange={handleActionChange}
          displayEmpty
          disabled={Object.keys(rowSelection).length === 0}
          sx={toolbarStyles.select}
        >
          <MenuItem value="" disabled>
            Select Action
          </MenuItem>
          <MenuItem value="Recommend">Recommend</MenuItem>
          <MenuItem value="ZeroArchive">Zero & Archive</MenuItem>
        </Select>

        {(subTab === 'Pending' || subTab === 'All') && (
          <Select
            size="small"
            value={selectedAction}
            onChange={handleResearchActionChange}
            displayEmpty
            disabled={Object.keys(rowSelection).length === 0}
            sx={toolbarStyles.select}
          >
            <MenuItem value="" disabled>Research</MenuItem>
            <MenuItem value="allocate">Move to Lead Hub</MenuItem>
            <MenuItem value="claim">Move to Claim Hub</MenuItem>
          </Select>
        )}

        {subTab === 'Done' && (
          <Button
            variant="outlined"
            size="small"
            disabled={Object.keys(rowSelection).length === 0}
            onClick={() => setOpenEmailAllocateDialog(true)}
            sx={toolbarStyles.button}
          >
            Allocate to Email Hub
          </Button>
        )}

        <ToggleButtonGroup
          size='small'
          color="primary"
          value={subTab}
          exclusive
          onChange={handleSubTabChange}
          aria-label="Sub Tab"
          sx={{
            height: '32px',
            '& .MuiToggleButtonGroup-grouped': {
              border: '1px solid #e0e0e0',
              '&:not(:first-of-type)': {
                borderLeft: '1px solid #e0e0e0',
              },
            },
          }}
        >
          {[
            { short: 'A', full: 'All', color: '#8797A8' },
            { short: 'P', full: 'Pending', color: '#FFC682' },
            { short: 'D', full: 'Done', color: '#C6CFFF' }
          ].map(({ short, full, color }) => (
            <Tooltip title={full} key={full}>
              <ToggleButton
                value={full}
                sx={{
                  textTransform: 'none',
                  px: 1.5,
                  minWidth: '32px'
                }}
              >
                <Typography sx={{
                  fontSize: '0.75rem',
                  fontWeight: '600',
                  color: color,
                }}>
                  {short}
                </Typography>
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>

        <CustomToggleButton
          value="nl-domain"
          selected={isNlDomain}
          onChange={() => setIsNlDomain(!isNlDomain)}
          label="NL"
          tooltipText="NL-Domain"
        />
      </Stack>

      <Box flexGrow={1} />
    </Stack>
  );

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Recommendation Hub
          </Typography>
          <Box flexGrow={1}></Box>
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab
                  key={listType}
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <Box>
                <RenewalTable
                  columns={columns}
                  data={data}
                  rowCount={totalElements}
                  isLoading={loading}
                  additionalColumns={columns}
                  columnVisibility={columnVisibility}
                  onDataChange={handleDataChange}

                  // Pass states
                  pagination={pagination}
                  sorting={sorting}
                  columnFilters={columnFilters}
                  globalSearch={globalSearch}
                  rowSelection={rowSelection}

                  // Pass state setters
                  setPagination={setPagination}
                  setSorting={setSorting}
                  setColumnFilters={setColumnFilters}
                  setGlobalSearch={setGlobalSearch}
                  setRowSelection={setRowSelection}

                  renderTopToolbarCustomActions={renderTopToolbarCustomActions}
                />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <QaAqLeadsDialog
          open={editDialogOpen}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedRow(null);
          }}
          data={{
            ...selectedRow,
            socialWorkId: selectedRow?.socialWorkId
          }}
          onSuccess={() => {
            fetchRecoHubData(tabValue);
          }}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        {/* Add new dialogs */}
        <Dialog fullWidth open={openAllocateDialog} onClose={() => { setOpenAllocateDialog(false); setSelectedAction("") }}>
          <DialogTitle>Allocate to Social User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
              <InputLabel id="user-select-label">Select User</InputLabel>
              <Select
                labelId="user-select-label"
                label="Select User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {children.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpenAllocateDialog(false); setSelectedAction("") }}>Cancel</Button>
            <Button onClick={handleAllocateToUser} variant="contained" disabled={allocating}>
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth open={openEmailAllocateDialog} onClose={() => setOpenEmailAllocateDialog(false)}>
          <DialogTitle>Allocate to Email User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
              <InputLabel id="email-user-select-label">Select User</InputLabel>
              <Select
                labelId="email-user-select-label"
                label="Select User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {children.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEmailAllocateDialog(false)}>Cancel</Button>
            <Button onClick={handleAllocateToEmailUser} variant="contained" disabled={allocating}>
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </ThemeProvider>
  );
}

export default React.memo(RecoHub);
